import {
  format,
  isSameDay,
  addDays,
  addSeconds,
  getTime as getTimestamp,
  formatDistanceStrict,
  isValid,
  toDate,
} from 'date-fns'
import { enUS as en, cs } from 'date-fns/locale'
import { I18n } from 'next-i18next'
import { now } from '@lib/withTimeSync'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'

const locales = { en, cs }

/**
 * Compare ISO 8601 dates using lexicographical sort order.
 * Returns a number indicating whether a reference date comes before or after or is the same as the given date in sort order.
 * - a negative number if the reference date occurs before the compare date
 * - positive if the reference date occurs after the compare date
 * - 0 if they are equivalent
 * (see https://en.wikipedia.org/wiki/ISO_8601#General_principles)
 */
export const compareIso8601Dates = (referenceDate: string, compareDate: string) =>
  referenceDate < compareDate ? -1 : referenceDate > compareDate ? 1 : 0

export const getDayName = (
  i18n: I18n,
  date: number | Date,
  options: {
    preposition?: boolean
    dayDate?: boolean
    dayFormat?: string
    dayName?: boolean
    dayNameFormat?: string
  } = { dayName: true },
) => {
  const { preposition, dayDate, dayFormat, dayName, dayNameFormat } = options
  const today = new Date(now())
  const newDate = new Date(date)
  if (isSameDay(today, newDate)) return i18n.t('common.today')
  if (isSameDay(addDays(today, 1), newDate)) return i18n.t('common.tomorrow')
  return `${preposition ? `${i18n.t('common.datePreposition')} ` : ''}${dayName
    ? format(newDate, dayNameFormat || 'iii', {
      locale: i18n.languages ? locales[i18n.languages[0]] : en,
    }) + ' '
    : ''
    }${dayDate
      ? format(newDate, dayFormat || 'MMM do', {
        locale: i18n.languages ? locales[i18n.languages[0]] : en,
      })
      : ''
    }`
}

export const getTime = (date: number | Date) => {
  return format(new Date(date), 'HH:mm')
}

export const formatInterval = (seconds: number, formatString = 'm:ss') => {
  return format(addSeconds(new Date('1970-01-01T00:00:00'), seconds), formatString)
}
export const getExpirationTimestamp = (seconds: number, date?: Date): number => {
  return getTimestamp(addSeconds(date ? new Date(date) : new Date(now()), seconds))
}

type Options = {
  addSuffix?: boolean
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
  roundingMethod?: 'floor' | 'ceil' | 'round'
  locale?: Locale
  wordLength?: number
}

// e.g. 15 minutes
export const formatLocalInterval = (i18n: I18n, seconds: number, options: Options = {}) => {
  if (seconds == null) return
  const { wordLength, ...FnsOptions } = options
  const start = new Date(now())
  const end = addSeconds(start, seconds)
  let interval = formatDistanceStrict(start, end, {
    locale: i18n.languages ? locales[i18n.languages[0]] : en,
    ...FnsOptions,
  })
  if (wordLength) {
    const [number, units] = interval.split(' ')
    if (!units) return interval // if the format is wrong just return the original (can happen in some translations)
    interval = [number, units.slice(0, wordLength)].join(' ')
  }
  return interval
}

// round to closest full call unit (typically 15m), e.g.: 2 hours 45 minutes
export const formatCallUnits = (i18n: I18n, units: number, unitLength = 1): string => {
  const hours = Math.floor(units / (60 / unitLength))
  const unitsLeft = Math.floor(units % (60 / unitLength))
  if (!hours && !unitsLeft) return null
  return `${hours ? formatLocalInterval(i18n, hours * 60 * 60) : ''}${hours ? ' ' : ''}${formatLocalInterval(
    i18n,
    unitsLeft * unitLength * 60,
  )}`
}

export const getIntervalAsHumanString = (interval: number, wordLength = 3): string => {
  const minutes = Math.floor(interval / 60)
  const seconds = interval % 60

  let minutesFormatted = ''
  let secondsFormatted = ''

  if (minutes > 0) {
    const word = 'minute'.slice(0, wordLength)
    minutesFormatted = `${minutes}\u00A0${word}`
  }

  if (seconds > 0) {
    const word = 'second'.slice(0, wordLength)
    secondsFormatted = `${seconds}\u00A0${word}`
  }

  return [minutesFormatted, secondsFormatted].filter(text => !!text).join(' ')
}

export const waitUntil = (timestamp: number, callback: () => void, tickInterval: number = 1000 / 30) => {
  const connectTimer = setInterval(() => {
    const currentTimestamp = new Date(now()).getTime()
    if (currentTimestamp >= timestamp) {
      clearInterval(connectTimer)
      callback()
    }
  }, tickInterval)

  return () => {
    clearInterval(connectTimer)
  }
}

export const parseDate = (date: string) => {
  const d = toDate(new Date(date))
  if (!isValid(d)) return undefined
  return d
}

export const getFormattedTime = (date: Date, locale = locales.cs, today?: string, yesterday?: string) => {
  const getTime = () => format(date, 'HH:mm', { locale })

  if (today && isToday(date)) return `${today} ${getTime()}`
  if (yesterday && isYesterday(date)) return `${yesterday} ${getTime()}`

  return format(date, 'eeee', { locale })
}
